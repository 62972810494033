<template>
  <v-chip
    small
    label
    :color="getColor(status)"
  >
    {{ status }}
  </v-chip>
</template>

<script>

export default {
  name: 'StatusBadge',

  props: {
    status: String
  },

  methods: {
    getColor(status) {
      if (status == 'active') return 'success'
    }
  }
}

</script>
